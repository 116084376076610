export const getDefaultTemplate = (id) => ({
    id,
    name: 'New Template',
    css: '',
    fields: [
        {
            name: 'Front',
        },
        {
            name: 'Back',
        },
    ],
    created: new Date(),
    edited: new Date(),
    templateType: {
        tag: 'standard',
        templates: [
            {
                id: 0,
                name: 'My Template',
                front: '{{Front}}',
                back: `{{FrontSide}}<hr id=answer>{{Back}}`,
            },
        ],
    },
    remotes: {},
});
export const getDefaultClozeTemplate = (id) => ({
    id,
    name: 'New Template',
    css: `.cloze-brackets-front {
    font-size: 150%%;
    font-family: monospace;
    font-weight: bolder;
    color: dodgerblue;
}
.cloze-filler-front {
    font-size: 150%%;
    font-family: monospace;
    font-weight: bolder;
    color: dodgerblue;
}
.cloze-brackets-back {
    font-size: 150%%;
    font-family: monospace;
    font-weight: bolder;
    color: red;
}`,
    fields: [
        {
            name: 'Text',
        },
        {
            name: 'Back Extra',
        },
    ],
    created: new Date(),
    edited: new Date(),
    templateType: {
        tag: 'cloze',
        template: {
            id: 0,
            name: 'My Template',
            front: '{{cloze:Text}}',
            back: `{{cloze:Text}}<br>{{Back Extra}}`,
        },
    },
    remotes: {},
});
